var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-error-message",
    { attrs: { for: "" + _vm._uid } },
    [
      _vm._t("default", function() {
        return [
          _vm._v(
            "There was an issue when submitting. Please check for any errors."
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }